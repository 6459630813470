import React, {useState} from "react";
import Layout from "../components/Layout";
import PricingTable from "../components/PricingTable";

function Faq() {
    const [one, setOne] = useState(false);
    const [two, setTwo] = useState(false);
    const [three, setThree] = useState(false);
    const [four, setFour] = useState(false);
    const [five, setFive] = useState(false);
    const [six, setSix] = useState(false);
    const [seven, setSeven] = useState(false);
    const [eight, setEight] = useState(false);
    const [nine, setNine] = useState(false);
    return (
        <Layout>
            <div>
                <div className="services_section_banner">
                    <div className="services_banner"
                         style={{backgroundImage: `url(../images/faq.jpg)`}}>
                        <h1>FAQ</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="card">
                        <div className="card__content">
                            <table>
                                <tbody>
                                <tr onClick={() => setOne(!one)}
                                    style={{cursor: 'pointer'}}>
                                    <td colSpan={11} style={{color: "black", fontSize: "large", fontWeight: "bold"}}>
                                        ¿Cómo funciona la línea de lavado de botellas de PET?
                                    </td>
                                    <td style={{border: 0}}>
                                        <img src="https://img.icons8.com/material-sharp/24/000000/sort-down.png"
                                             style={{width: 15, height: 10}}/>
                                    </td>
                                </tr>
                                <tr style={{display: (one ? "" : "none")}}>
                                    <td colSpan={12} style={{color: "black", fontSize: "large"}}>
                                        En el proceso de lavado lo que hacemos es eliminar las impurezas que
                                        están mezcladas por dentro y afuera de las botellas. Zion Tech conoce los
                                        diferentes caracteres de las impurezas, por lo que diseñamos diferentes máquinas
                                        con sus distintas funciones para separar las impurezas específicas. Cada línea
                                        de lavado fueron diseñado bajo el acuerdo de la demanda del cliente y las
                                        particularidades presentadas bajo las botellas de PET de desecho.
                                    </td>
                                </tr>

                                <tr onClick={() => setTwo(!two)}
                                    style={{cursor: 'pointer'}}>
                                    <td colSpan={11} style={{color: "black", fontSize: "large", fontWeight: "bold"}}>
                                        ¿Cómo lava las botellas la máquina de prelavado de botellas de PET?
                                    </td>
                                    <td style={{border: 0}}>
                                        <img src="https://img.icons8.com/material-sharp/24/000000/sort-down.png"
                                             style={{width: 15, height: 10}}/>
                                    </td>
                                </tr>
                                <tr style={{display: (two ? "" : "none")}}>
                                    <td colSpan={12} style={{color: "black", fontSize: "large"}}>
                                        En la lavadora de botellas se lava las botellas con agua caliente y
                                        con productos químicos. La estructura son especialmente diseñada, la cual hace
                                        que se circule en los recorridos designados con una duración determinada en las
                                        botellas, y con fuerza fricción ejercida sobre la superficie de las botellas
                                        eliminará el barro, la arena, la grasa, los pegamentos, mediante la fricción y
                                        la solución química con agua caliente logra de quitar la etiqueta de papel y de
                                        PP. Lo más importante, es que logra blanquear las botellas de PVC con agua
                                        embullido, lo cual logra distinguirlas fácilmente entre las botellas de PET en
                                        el siguiente proceso de la clasificación manual.
                                    </td>
                                </tr>

                                <tr onClick={() => setThree(!three)}
                                    style={{cursor: 'pointer'}}>
                                    <td colSpan={11} style={{color: "black", fontSize: "large", fontWeight: "bold"}}>
                                        ¿Qué tipo de producto químico utiliza para lavar botellas de PET y escamas de
                                        PET?
                                    </td>
                                    <td style={{border: 0}}>
                                        <img src="https://img.icons8.com/material-sharp/24/000000/sort-down.png"
                                             style={{width: 15, height: 10}}/>
                                    </td>
                                </tr>
                                <tr style={{display: (three ? "" : "none")}}>
                                    <td colSpan={12} style={{color: "black", fontSize: "large"}}>
                                        De acuerdo con el estado de la empacadora de botellas de PET, Zion Tech
                                        recomienda siempre usar con dos productos químicos para limpiar botellas de PET
                                        y escamas de PET: el detergente de limpieza y NaOH. El detergente que elegimos
                                        es la marca europea, y nos suministra su representación comercial en nuestro
                                        país. Generalmente, una tonelada de producción de escamas de PET requerirá 2 kg
                                        de NaOH y 1,5 kg de detergentes de limpieza.
                                    </td>
                                </tr>

                                <tr onClick={() => setFour(!four)}
                                    style={{cursor: 'pointer'}}>
                                    <td colSpan={11} style={{color: "black", fontSize: "large", fontWeight: "bold"}}>
                                        ¿Qué función de separación cumple la máquina clasificadora de la banda
                                        transportadora ?
                                    </td>
                                    <td style={{border: 0}}>
                                        <img src="https://img.icons8.com/material-sharp/24/000000/sort-down.png"
                                             style={{width: 15, height: 10}}/>
                                    </td>
                                </tr>
                                <tr style={{display: (four ? "" : "none")}}>
                                    <td colSpan={12} style={{color: "black", fontSize: "large"}}>
                                        Botellas de colores, botellas NO PET, metales y otras contaminaciones
                                        mezcladas con la materia prima.
                                    </td>
                                </tr>

                                <tr onClick={() => setFive(!five)}
                                    style={{cursor: 'pointer'}}>
                                    <td colSpan={11} style={{color: "black", fontSize: "large", fontWeight: "bold"}}>
                                        ¿Cómo es el sistema de circulación de agua química?
                                    </td>
                                    <td style={{border: 0}}>
                                        <img src="https://img.icons8.com/material-sharp/24/000000/sort-down.png"
                                             style={{width: 15, height: 10}}/>
                                    </td>
                                </tr>
                                <tr style={{display: (five ? "" : "none")}}>
                                    <td colSpan={12} style={{color: "black", fontSize: "large"}}>
                                        El agua será vertida sobre una pantalla de vibración que separa la
                                        suciedad del agua como barro, arena, papel y etiquetas de PP, se utiliza un
                                        tanque de depósito para sedimentar el lodo fino del producto químico, y con la
                                        pantalla giratoria se elimina la micro-suciedad del producto químico, luego
                                        enviarlos al tanque de circulación para calentarlo, y vuelve a re-circular la
                                        solución química para ser reutilizada nuevamente para lavar las botellas.
                                        Normalmente, una tonelada de producción de escamas de PET debe complementar 2.5
                                        toneladas de agua industrial.
                                    </td>
                                </tr>

                                <tr onClick={() => setSix(!six)}
                                    style={{cursor: 'pointer'}}>
                                    <td colSpan={11} style={{color: "black", fontSize: "large", fontWeight: "bold"}}>
                                        ¿Por qué tritura o muelen las botellas con agua?
                                    </td>
                                    <td style={{border: 0}}>
                                        <img src="https://img.icons8.com/material-sharp/24/000000/sort-down.png"
                                             style={{width: 15, height: 10}}/>
                                    </td>
                                </tr>
                                <tr style={{display: (six ? "" : "none")}}>
                                    <td colSpan={12} style={{color: "black", fontSize: "large"}}>
                                        El agua puede enfriar el rotor, a su vez produce una fuerza de
                                        fricción sobre la superficie de las escamas, el agua puede limpiar las impurezas
                                        que adhieren sobre las escamas después de ser trituradas, ésta en la primera
                                        etapa del lavado de las escamas.
                                    </td>
                                </tr>

                                <tr onClick={() => setSeven(!seven)}
                                    style={{cursor: 'pointer'}}>
                                    <td colSpan={11} style={{color: "black", fontSize: "large", fontWeight: "bold"}}>
                                        ¿Cómo elimina los metales el sistema de reciclaje de PET?
                                    </td>
                                    <td style={{border: 0}}>
                                        <img src="https://img.icons8.com/material-sharp/24/000000/sort-down.png"
                                             style={{width: 15, height: 10}}/>
                                    </td>
                                </tr>
                                <tr style={{display: (seven ? "" : "none")}}>
                                    <td colSpan={12} style={{color: "black", fontSize: "large"}}>
                                        Los metales generalmente incluyen metales ferrosos y metales no
                                        ferrosos. Para eliminar metales ferrosos, generalmente usamos rodillos
                                        magnéticos, removedores de hierro suspendidos y detectores de metales adaptado
                                        con un dispositivo de aleteo, y eliminamos tanto como sea posible antes de
                                        entrar en la sección de trituración, lo cual es beneficioso para proteger las
                                        cuchillas trituradoras Los dispositivos de corrientes de Foucault o conocido
                                        como Eddy Current se utilizan generalmente para metales no ferrosos.
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Faq
